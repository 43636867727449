var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users"},[_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.pageName))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":"2"}},[_c('v-select',{attrs:{"items":_vm.caseTypes,"label":"Case Type","clearable":""},model:{value:(_vm.caseTypeFilterValue),callback:function ($$v) {_vm.caseTypeFilterValue=$$v},expression:"caseTypeFilterValue"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-select',{attrs:{"items":_vm.caseTDA,"label":"TDA","clearable":""},model:{value:(_vm.caseTDAFilterValue),callback:function ($$v) {_vm.caseTDAFilterValue=$$v},expression:"caseTDAFilterValue"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-select',{attrs:{"items":_vm.caseStatus,"label":"Case Status","clearable":""},model:{value:(_vm.caseStatusFilterValue),callback:function ($$v) {_vm.caseStatusFilterValue=$$v},expression:"caseStatusFilterValue"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-select',{attrs:{"items":_vm.segRegion,"label":"Seg Region","clearable":""},model:{value:(_vm.segRegionFilterValue),callback:function ($$v) {_vm.segRegionFilterValue=$$v},expression:"segRegionFilterValue"}})],1),_c('v-col',{staticClass:"ml-auto",attrs:{"sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search this list...","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.cases,"loading":_vm.loading,"search":_vm.search,"items-per-page":5,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('pre',[_vm._v(_vm._s(item))])])]}},{key:"item.isTDA",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.isTDA ? 'TDA' : 'Legacy')+" ")]}},{key:"item.mpsName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.coveringRepSurname)+", "+_vm._s(item.coveringRepGivenName)+" ")]}},{key:"item.shareDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.shareDate)))]}},{key:"item.needDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.needDate)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"top":"","left":"","tile":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[_c('v-list-item',{on:{"click":function($event){return _vm.onViewCase(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("View")])],1)],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAllowedToEdit(item) && !_vm.isSeniorSegRep),expression:"isAllowedToEdit(item) && !isSeniorSegRep"}],on:{"click":function($event){return _vm.onEditCase(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit")])],1)],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAllowedToOffHold(item) && !_vm.isSeniorSegRep),expression:"isAllowedToOffHold(item) && !isSeniorSegRep"}],on:{"click":function($event){return _vm.onTakeOffHold(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-timer-off")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Take off On Hold")])],1)],1),(
                    _vm.isSystemAdmin ||
                      (_vm.isLeadAnalyst && item.caseStatus != 'Uploaded') ||
                      _vm.isSeniorSupervisor ||
                      _vm.isManagerSeniorManager
                  )?_c('v-list-item',{on:{"click":function($event){return _vm.onAdminCase(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-lock")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Admin")])],1)],1):_vm._e(),(_vm.isSystemAdmin)?_c('v-list-item',{on:{"click":function($event){return _vm.onDeleteCase(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete")])],1)],1):_vm._e()],1)],1)],1)]}}],null,true)})],1)],1),_c('v-btn',{attrs:{"bottom":"","color":"amber","fab":"","fixed":"","right":""},on:{"click":_vm.onCreateCase}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('CreateCase',{key:_vm.createCaseKey,attrs:{"showDialog":_vm.dialog,"cases":_vm.cases,"users":_vm.users},on:{"dialog-state-change":_vm.onDialogStateChange}}),_c('AdminCase',{key:_vm.adminCaseKey,attrs:{"showAdminDialog":_vm.adminDialog,"caseObject":_vm.caseObject,"users":_vm.users},on:{"admin-dialog-state-change":_vm.onAdminDialogStateChange}}),_c('TakeOffHold',{key:_vm.takeOffHoldKey,attrs:{"showOnTakeOffHoldDialog":_vm.takeOffHoldDialog,"caseObject":_vm.caseObject,"users":_vm.users},on:{"off-hold-dialog-state-change":_vm.onTakeOffHoldDialogStateChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }