<template>
  <div class="on-take-off-hold">
    <v-dialog v-model="isOffHoldDialogVisible" persistent width="720px">
      <v-card>
        <v-card-title class="amber"
          ><span class="headline">Take off Hold - {{ caseObject.UniqueIdentifier }}</span></v-card-title
        >
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col sm="12">
                  <p>Are you sure you want to take this case off hold?</p>
                  <p>
                    This case will be returned back to <b>{{ caseStatus }}</b> status.
                  </p>
                </v-col>
                <v-col sm="12">
                  <v-textarea label="Internal Case Notes" v-model="internalCaseNotes" rows="3"></v-textarea>
                </v-col>
                <v-col sm="6">
                  <v-select
                    :items="poorScanReasons"
                    v-model="poorScanReason"
                    label="Select poor scan reason(s)"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip x-small v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ poorScanReason.length - 1 }} more)
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col sm="6">
                  <v-select
                    :items="rightFirstTimeCodes"
                    v-model="rightFirstTime"
                    label="Select right first time code(s)"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip x-small v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption" s>
                        (+{{ rightFirstTime.length - 1 }} more)
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-btn text @click="onCancel">Cancel</v-btn>
          <v-btn text color="warning" :disabled="!valid" @click="offHold">Off-Hold</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'on-hold',
  props: {
    showOnTakeOffHoldDialog: {
      type: Boolean,
      default: false,
    },
    caseObject: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      valid: false,
      internalCaseNotes: '',
      poorScanReason: '',
      rightFirstTime: '',
    };
  },
  computed: {
    ...mapGetters('accounts', { currentAccount: 'getCurrentAccount' }),
    isOffHoldDialogVisible: {
      get() {
        return this.showOnTakeOffHoldDialog;
      },
      set(value) {
        this.$emit('off-hold-dialog-state-change', value);
      },
    },
    poorScanReasons() {
      if (this.caseObject.caseType === 'TKA') {
        return [
          'Incorrect number of files',
          'Need by date change',
          'Noise/Scatter',
          'Resolution matrix greater than 512x512',
          'Reverted to legacy -case not in TDA',
          'Reverted to legacy -case rejected in TDA',
          'Rotated ankle',
          'Side switch',
        ];
      } else {
        return [
          'Bent/rotated leg',
          'Incorrect number of files',
          'Need by date change',
          'Noise/Scatter',
          'Resolution matrix greater than 512x512',
          'Reverted to legacy -case not in TDA',
          'Reverted to legacy -case rejected in TDA',
          'Side switch',
        ];
      }
    },
    rightFirstTimeCodes() {
      if (this.caseObject.caseType === 'TKA' && this.caseObject.isTDA === false) {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect patient downloaded',
          '[DR] Not submitted for high-risk evaluation',
          '[DR] Work not saved – no files (UTT)',
          '[PR] False acceptance/rejection',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Not submitted for high-risk evaluation',
          '[PR] Plan not attached',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      } else if (
        (this.caseObject.caseType === 'THA' || this.caseObject.caseType === 'Cadaver') &&
        this.caseObject.isTDA === false
      ) {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect patient downloaded',
          '[DR] Incorrect series selected',
          '[DR] Not submitted for high-risk evaluation',
          '[DR] Work not saved – no files (UTT)',
          '[IS] False acceptance/rejection',
          '[IS] Model(s) labeled incorrectly',
          '[IS] Not submitted for high-risk evaluation',
          '[IS] Over/under segmentation',
          '[IS] Threshold not set to minimum',
          '[IS] Work not saved – no files (UTT)',
          '[FS] False acceptance/rejection',
          '[FS] Improper hemi-cut',
          '[FS] Not submitted for high-risk evaluation',
          '[FS] Over/under segmentation',
          '[FS] STLs labeled incorrectly',
          '[FS] Work not saved – no files (UTT)',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      } else {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect series selected',
          '[DR] Not submitted for high-risk evaluation',
          '[IS] False acceptance/rejection',
          '[IS] Not submitted for high-risk evaluation',
          '[IS] Over/under segmentation',
          '[FS] False acceptance/rejection',
          '[FS] Improper hemi-cut/Short models',
          '[FS] Not submitted for high-risk evaluation',
          '[FS] Over/under segmentation',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Plan not attached',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      }
    },
    caseStatus() {
      if (this.caseObject.caseStatus.includes('Data Review')) {
        return 'Awaiting - Data Review';
      }
      if (this.caseObject.caseStatus.includes('Initial Segmentation')) {
        return 'Awaiting - Initial Segmentation';
      }
      if (this.caseObject.caseStatus.includes('Segmentation Review')) {
        return 'Awaiting - Segmentation Review';
      } else {
        return 'Awaiting - Plan Review & Upload';
      }
    },
  },
  methods: {
    ...mapActions('cases', { updateCase: 'updateCase' }),
    onCancel() {
      this.$refs.form.reset();
      this.isOffHoldDialogVisible = false;
    },
    async offHold() {
      this.caseObject['onHoldReason'] = [];
      this.caseObject['onHoldCaseNotes'] = '';
      this.caseObject['internalCaseNotes'] = `Case taken off hold by ${this.currentAccount.lastName}, ${
        this.currentAccount.firstName
      } at ${Date.now()} ${this.internalCaseNotes}`;
      this.caseObject['poorScanReason'] = this.poorScanReason;
      this.caseObject['rightFirstTime'] = this.rightFirstTime;
      this.caseObject['isOnHold'] = false;
      this.caseObject['updatedBy'] = this.$store.state.accounts.account.username;
      this.caseObject['updatedDate'] = this.$moment().utc();
      if (this.caseObject.caseStatus.includes('Data Review')) {
        this.caseObject['caseStatus'] = 'Awaiting Data Review';
      }
      if (this.caseObject.caseStatus.includes('Initial Segmentation')) {
        this.caseObject['caseStatus'] = 'Awaiting Initial Segmentation';
      }
      if (this.caseObject.caseStatus.includes('Segmentation Review')) {
        this.caseObject['caseStatus'] = 'Awaiting Segmentation Review';
      }
      if (this.caseObject.caseStatus.includes('Plan Review & Upload')) {
        this.caseObject['caseStatus'] = 'Awaiting Plan Review & Upload';
      }
      await this.updateCase(this.caseObject);
      this.isOffHoldDialogVisible = false;
    },
  },
};
</script>

<style></style>
