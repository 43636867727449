<template>
  <div class="users">
    <v-row>
      <v-col sm="12">
        <span class="headline">{{ pageName }}</span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="2">
        <v-select v-model="caseTypeFilterValue" :items="caseTypes" label="Case Type" clearable></v-select>
      </v-col>
      <v-col sm="2">
        <v-select v-model="caseTDAFilterValue" :items="caseTDA" label="TDA" clearable></v-select>
      </v-col>
      <v-col sm="2">
        <v-select v-model="caseStatusFilterValue" :items="caseStatus" label="Case Status" clearable></v-select>
      </v-col>
      <v-col sm="2">
        <v-select v-model="segRegionFilterValue" :items="segRegion" label="Seg Region" clearable></v-select>
      </v-col>
      <v-col sm="3" class="ml-auto">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search this list..." single-line></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="cases"
          :loading="loading"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <pre>{{ item }}</pre>
            </td>
          </template>
          <template v-slot:[`item.isTDA`]="{ item }">{{ item.isTDA ? 'TDA' : 'Legacy' }} </template>
          <template v-slot:[`item.mpsName`]="{ item }"
            >{{ item.coveringRepSurname }}, {{ item.coveringRepGivenName }}
          </template>
          <template v-slot:[`item.shareDate`]="{ item }">{{ item.shareDate | formatDate }}</template>
          <template v-slot:[`item.needDate`]="{ item }">{{ item.needDate | formatDate }}</template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu top left tile>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item @click="onViewCase(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-eye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>View</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-show="isAllowedToEdit(item) && !isSeniorSegRep" @click="onEditCase(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item v-show="isAllowedToUnAssign(item) && !isSeniorSegRep">
                    <v-list-item-icon>
                      <v-icon>mdi-account-arrow-left</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Un-Assign</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-list-item v-show="isAllowedToOffHold(item) && !isSeniorSegRep" @click="onTakeOffHold(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-timer-off</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Take off On Hold</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item v-show="isAllowedToUnReject(item) && !isSeniorSegRep">
                    <v-list-item-icon>
                      <v-icon>mdi-clipboard-arrow-left</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Un-Reject</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-list-item
                    v-if="
                      isSystemAdmin ||
                        (isLeadAnalyst && item.caseStatus != 'Uploaded') ||
                        isSeniorSupervisor ||
                        isManagerSeniorManager
                    "
                    @click="onAdminCase(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-lock</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Admin</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="isSystemAdmin" @click="onDeleteCase(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-btn bottom color="amber" fab fixed right @click="onCreateCase">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <CreateCase
      :showDialog="dialog"
      :cases="cases"
      :users="users"
      @dialog-state-change="onDialogStateChange"
      :key="createCaseKey"
    />
    <AdminCase
      :showAdminDialog="adminDialog"
      :caseObject="caseObject"
      :users="users"
      @admin-dialog-state-change="onAdminDialogStateChange"
      :key="adminCaseKey"
    />
    <TakeOffHold
      :showOnTakeOffHoldDialog="takeOffHoldDialog"
      :caseObject="caseObject"
      :users="users"
      @off-hold-dialog-state-change="onTakeOffHoldDialogStateChange"
      :key="takeOffHoldKey"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CreateCase from '@/components/CreateCase.vue';
import AdminCase from '@/components/AdminCase.vue';
import TakeOffHold from '@/components/OnTakeOffHold.vue';
export default {
  name: 'Cases',
  components: { CreateCase, AdminCase, TakeOffHold },
  data() {
    return {
      pageName: 'Cases',
      dialog: false,
      adminDialog: false,
      createTPRDialog: false,
      takeOffHoldDialog: false,

      createCaseKey: 0,
      adminCaseKey: 100,
      createTPRKey: 200,
      takeOffHoldKey: 300,

      casesKey: 500,
      search: null,
      caseTypeFilterValue: '',
      caseTDAFilterValue: '',
      caseStatusFilterValue: '',
      segRegionFilterValue: '',
      expanded: [],
      singleExpand: true,
      caseObject: {},
      task: {},
    };
  },
  computed: {
    ...mapGetters('cases', { cases: 'getCases', loading: 'getLoading' }),
    ...mapGetters('accounts', { account: 'getAccount' }),
    ...mapGetters('users', { users: 'getUsers' }),
    headers() {
      return [
        {
          text: 'Unique Identifier',
          value: 'UniqueIdentifier',
        },
        {
          text: 'Case Type',
          value: 'caseType',
          filter: this.caseTypeFilter,
        },
        {
          text: 'TDA',
          value: 'isTDA',
          filter: this.caseTDAFilter,
        },
        {
          text: 'Case Status',
          value: 'caseStatus',
          filter: this.caseStatusFilter,
        },
        {
          text: 'Seg Region',
          value: 'segRegion',
          filter: this.segRegionFilter,
        },
        {
          text: 'MPS Name',
          value: 'mpsName',
        },
        {
          text: 'Patient Name',
          value: 'patientName',
        },
        {
          text: 'Primary Exam Id',
          value: 'primaryExamId',
        },
        {
          text: 'Share Date',
          value: 'shareDate',
        },
        {
          text: 'Need by Date',
          value: 'needDate',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ];
    },
    caseTypes() {
      const type = this.cases.map((item) => item.caseType);
      return type;
    },
    caseTDA() {
      return ['TDA', 'Legacy'];
    },
    caseStatus() {
      const status = this.cases.map((item) => item.caseStatus);
      return status;
    },
    segRegion() {
      return ['US - Fort Lauderdale, FL', 'US - Kalamazoo, MI', 'APAC', 'SSP'];
    },
    isSystemAdmin() {
      return this.$store.state.accounts.currentAccount.role === 'System Admin';
    },
    isManagerSeniorManager() {
      return this.$store.state.accounts.currentAccount.role === 'Manager / Senior Manager';
    },
    isSeniorSupervisor() {
      return this.$store.state.accounts.currentAccount.role === 'Senior Supervisor';
    },
    isLeadAnalyst() {
      return this.$store.state.accounts.currentAccount.role === 'Lead / Analyst';
    },
    isSeniorSegRep() {
      return this.$store.state.accounts.currentAccount.role === 'Senior Seg Rep';
    },
    isAllowedToCreateTPR() {
      return (item) => {
        return (
          item.caseStatus === 'Awaiting Data Review' &&
          item.isTDA === false &&
          item.secondaryExamId === '' &&
          item.currentlyAssignedRep === ''
        );
      };
    },
    isAllowedToEdit() {
      return (item) => {
        return item.caseStatus !== 'Uploaded';
      };
    },
    isAllowedToUnReject() {
      return (item) => {
        return item.caseStatus.includes('Rejected');
      };
    },
    isAllowedToOffHold() {
      return (item) => {
        return item.caseStatus.includes('On Hold');
      };
    },
    isAllowedToUnAssign() {
      return (item) => {
        return item.currentlyAssignedRep !== '';
      };
    },
  },
  created() {
    this.$store.dispatch('cases/getCases');
  },
  methods: {
    ...mapActions('cases', { deleteCase: 'deleteCase', updateCase: 'updateCase' }),
    onDialogStateChange(state) {
      this.dialog = state;
    },
    onAdminDialogStateChange(state) {
      this.adminDialog = state;
    },
    onCreateTPRDialogStateChange(state) {
      this.createTPRDialog = state;
    },
    onTakeOffHoldDialogStateChange(state) {
      this.takeOffHoldDialog = state;
    },
    onUnAssignDialogStateChange(state) {
      this.takeOffHoldDialog = state;
    },
    forceRerender() {
      this.createCaseKey += 1;
      this.adminCaseKey += 1;
    },
    onCreateCase() {
      this.dialog = true;
    },
    onAdminCase(item) {
      this.caseObject = item;
      this.adminDialog = true;
    },
    onTakeOffHold(item) {
      this.caseObject = item;
      this.takeOffHoldDialog = true;
    },
    onUnAssign(item) {
      this.task = item;
      this.unAssignTaskDialog = true;
    },
    onEditCase(item) {
      this.$router.push({ name: 'Case', params: { id: item.id } });
    },
    onViewCase(item) {
      this.$router.push({ name: 'Case View', params: { id: item.id } });
    },
    onDeleteCase(item) {
      this.deleteCase(item);
    },
    // Datatable filters
    caseTypeFilter(value) {
      if (!this.caseTypeFilterValue) {
        return true;
      }

      return value === this.caseTypeFilterValue;
    },
    caseTDAFilter(value) {
      if (!this.caseTDAFilterValue) {
        return true;
      }
      if (this.caseTDAFilterValue === 'TDA') {
        return value === true;
      }
      if (this.caseTDAFilterValue === 'Legacy') {
        return value !== true;
      }
    },
    caseStatusFilter(value) {
      if (!this.caseStatusFilterValue) {
        return true;
      }

      return value === this.caseStatusFilterValue;
    },
    segRegionFilter(value) {
      if (!this.segRegionFilterValue) {
        return true;
      }

      return value === this.segRegionFilterValue;
    },
  },
  watch: {
    dialog: function(newValue) {
      if (!newValue) {
        console.log('Dialog Rerender: ', { newValue: newValue });
        this.forceRerender();
      }
    },
    adminDialog: function(newValue) {
      if (!newValue) {
        console.log('Dialog Rerender: ', { newValue: newValue });
        this.forceRerender();
      }
    },
    takeOffHoldDialog: function(newValue) {
      if (!newValue) {
        console.log('Take off hold Dialog Rerender: ', { newValue: newValue });
        this.takeOffHoldKey += 1;
        this.$store.dispatch('cases/getCases');
        this.$forceUpdate();
      }
    },
  },
};
</script>
