<template>
  <div class="admin-case">
    <v-dialog v-model="isAdminDialogVisible" persistent width="960px">
      <v-card>
        <v-card-title class="amber"><span class="headline">Admin Case</span></v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row dense>
                <v-col sm="3">
                  <v-text-field v-model="caseObject.UniqueIdentifier" label="Unique Identifier" readonly></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-text-field v-model="caseObject.patientName" label="Patient Name" readonly></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-text-field v-model="caseObject.primaryExamId" label="Primary Exam Id" readonly></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-text-field v-model="caseObject.secondaryExamId" label="Secondary Exam Id"></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-select :items="mpsRegions" v-model="caseObject.coveringRepRegion" label="MPS Region"></v-select>
                </v-col>
                <v-col sm="3" offset="3">
                  <v-select :items="segRegions" v-model="caseObject.segRegion" label="Seg Region"></v-select>
                </v-col>
                <v-col sm="3">
                  <v-switch v-model="caseObject.isTDA" label="TDA"></v-switch>
                </v-col>
              </v-row>
              <!-- <v-row dense>
                <v-col sm="12">
                  <v-autocomplete
                    v-model="dataRevieRepEmail"
                    :items="userProfiles"
                    clearable
                    label="Data Review Rep Email"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-content>
                          <v-list-item-title v-text="`${item}`"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col sm="12">
                  <v-autocomplete
                    v-model="caseObject.initialSegmentation"
                    :items="userProfiles"
                    clearable
                    label="Initial Segmentation Rep Email"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-content>
                          <v-list-item-title v-text="`${item}`"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col sm="12">
                  <v-autocomplete
                    v-model="caseObject.segmentationReview"
                    :items="userProfiles"
                    clearable
                    label="Segmentation Review Rep Email"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-content>
                          <v-list-item-title v-text="`${item}`"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col sm="12">
                  <v-autocomplete
                    v-model="caseObject.planReviewUpload"
                    :items="userProfiles"
                    clearable
                    label="Plan Review and Upload Rep Email"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-content>
                          <v-list-item-title v-text="`${item}`"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col sm="6">
                  <v-autocomplete
                    v-model="caseObject.currentlyAssignedRep"
                    :items="userProfiles"
                    clearable
                    label="Currently Assigned Rep Email"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-content>
                          <v-list-item-title v-text="`${item}`"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col sm="6">
                  <v-select :items="caseStatuses" v-model="caseObject.caseStatus" label="Case Status"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6">
                  <v-select
                    :items="poorScanReasons"
                    v-model="poorScanReason"
                    label="Select poor scan reason(s)"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip x-small v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ poorScanReason.length - 1 }} more)
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col sm="6">
                  <v-select
                    :items="rightFirstTimeCodes"
                    v-model="rightFirstTime"
                    label="Select right first time code(s)"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip x-small v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption" s>
                        (+{{ rightFirstTime.length - 1 }} more)
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="12">
                  <v-textarea
                    label="Internal Case Notes"
                    v-model="caseObject.internalCaseNotes"
                    rows="2"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-btn text @click="cancel">Cancel</v-btn>
          <v-btn text color="warning" @click="update">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'create-case',
  props: {
    showAdminDialog: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      require: false,
    },
    caseObject: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      valid: false,
      segRegions: ['US - Fort Lauderdale, FL', 'US - Kalamazoo, MI', 'APAC', 'SSP'],
      mpsRegions: ['US', 'APAC', 'SSP'],
      poorScanReason: '',
      rightFirstTime: '',
      caseStatuses: [
        'Awaiting Data Review',
        'Pending TPR Creation',
        'Assigned - Data Review',
        'On Hold - Data Review',
        'Rejected - Data Review',
        'Awaiting Initial Segmentation',
        'Assigned - Initial Segmentation',
        'On Hold - Initial Segmentation',
        'Rejected - Initial Segmentation',
        'Awaiting Segmentation Review',
        'Assigned - Segmentation Review',
        'On Hold - Segmentation Review',
        'Rejected - Segmentation Review',
        'Awaiting Plan Review & Upload',
        'Assigned - Plan Review & Upload',
        'On Hold - Plan Review & Upload',
        'Rejected - Plan Review & Upload',
        'Uploaded',
        'Ongoing Investigation',
      ],
      insertTypes: ['CR', 'CS', 'PS'],
      basePlateTypes: ['Cruciform', 'Universal'],
      approachTypes: ['Postero-Lateral', 'Antero-Lateral', 'Direct Anterior', 'Direct Superior (THA 4.0 only)'],
      acetabularComponentTypes: [
        'Trident Hemispherical',
        'Trident PSL',
        'Trident Tritanium',
        'Trident II Hemispherical (THA 4.0 only)',
        'Trident II PSL (THA 4.0 only)',
        'Trident II Tritanium',
      ],
      femoralComponentTypes: ['Accolade II', 'Secur-Fit Advanced', 'Anato', 'Exeter', 'Exeter US'],
    };
  },
  computed: {
    ...mapGetters('accounts', { account: 'getAccount' }),
    isAdminDialogVisible: {
      get() {
        return this.showAdminDialog;
      },
      set(value) {
        this.$emit('admin-dialog-state-change', value);
      },
    },
    userProfiles() {
      return this.users.filter((user) => user.userType === 'User').map((user) => user.email);
    },
    poorScanReasons() {
      if (this.caseObject.caseType === 'TKA') {
        return [
          'Incorrect number of files',
          'Need by date change',
          'Noise/Scatter',
          'Resolution matrix greater than 512x512',
          'Reverted to legacy -case not in TDA',
          'Reverted to legacy -case rejected in TDA',
          'Rotated ankle',
          'Side switch',
        ];
      } else {
        return [
          'Bent/rotated leg',
          'Incorrect number of files',
          'Need by date change',
          'Noise/Scatter',
          'Resolution matrix greater than 512x512',
          'Reverted to legacy -case not in TDA',
          'Reverted to legacy -case rejected in TDA',
          'Side switch',
        ];
      }
    },
    rightFirstTimeCodes() {
      if (this.caseObject.caseType === 'TKA' && this.caseObject.isTDA === false) {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect patient downloaded',
          '[DR] Not submitted for high-risk evaluation',
          '[DR] Work not saved – no files (UTT)',
          '[PR] False acceptance/rejection',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Not submitted for high-risk evaluation',
          '[PR] Plan not attached',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      } else if (
        (this.caseObject.caseType === 'THA' || this.caseObject.caseType === 'Cadaver') &&
        this.caseObject.isTDA === false
      ) {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect patient downloaded',
          '[DR] Incorrect series selected',
          '[DR] Not submitted for high-risk evaluation',
          '[DR] Work not saved – no files (UTT)',
          '[IS] False acceptance/rejection',
          '[IS] Model(s) labeled incorrectly',
          '[IS] Not submitted for high-risk evaluation',
          '[IS] Over/under segmentation',
          '[IS] Threshold not set to minimum',
          '[IS] Work not saved – no files (UTT)',
          '[FS] False acceptance/rejection',
          '[FS] Improper hemi-cut',
          '[FS] Not submitted for high-risk evaluation',
          '[FS] Over/under segmentation',
          '[FS] STLs labeled incorrectly',
          '[FS] Work not saved – no files (UTT)',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      } else {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect series selected',
          '[DR] Not submitted for high-risk evaluation',
          '[IS] False acceptance/rejection',
          '[IS] Not submitted for high-risk evaluation',
          '[IS] Over/under segmentation',
          '[FS] False acceptance/rejection',
          '[FS] Improper hemi-cut/Short models',
          '[FS] Not submitted for high-risk evaluation',
          '[FS] Over/under segmentation',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Plan not attached',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      }
    },
    dataRevieRepEmail: {
      get() {
        if (this.caseObject.dataReview && this.caseObject.dataReview.repEmail) {
          return this.caseObject.dataReview.repEmail;
        } else {
          return '';
        }
      },
      set(value) {
        this.$set(this.caseObject, this.caseObject.dataReview.repEmail, value);
      },
    },
  },
  methods: {
    ...mapActions('cases', { updateCase: 'updateCase' }),
    async update() {
      this.caseObject['poorScanReason'] = this.poorScanReason;
      this.caseObject['rightFirstTime'] = this.rightFirstTime;
      this.caseObject['updatedBy'] = this.$store.state.accounts.account.username;
      this.caseObject['updatedDate'] = this.$moment().utc();
      await this.updateCase(this.caseObject);
      this.isAdminDialogVisible = false;
      console.log(this.caseObject);
    },

    async cancel() {
      this.isAdminDialogVisible = false;
    },
  },
  watch: {},
};
</script>

<style></style>
